import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';
import Notification from '../Components/Notifications';
import Confirmation from "../Components/Confirmation";
import { getCookieValue } from "../Context/utils";


const ProgressChecker = () => {
  const [session, setSession] = useState(null);
  const [queueData, setQueueData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [showNotification, setShowNotification] = useState(false)
  const [message, setMessage] = useState('')
  const [type, setType] = useState('')

  const [confirmationData, setConfirmationData] = useState({heading:'', message: ''})
  const [selectedQid, setSelectedQid] = useState(null)
  const [showConfirmation, setShowConfirmation] = useState(false)

  useEffect(() => {
    const session = getCookieValue('sessionid');
    setSession(session);

    if (session) {
      fetch(`https://pre-potts-middleware.comfort-works.com/queues/?session=${session}`)
        .then(response => response.json())
        .then(data => {
          if (data.status) {
            // Sort the queue data here
            const sortedData = data.queue.sort((a, b) => {
              if (a.done === 1 && b.done !== 1) return 1;
              if (a.done !== 1 && b.done === 1) return -1;
              if (a.inProgress < b.inProgress) return -1;
              if (a.inProgress > b.inProgress) return 1;
              return 0;
            });
            setQueueData(sortedData);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err.message);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const setNotificationFields = (m, t) => {
    setMessage(m);
    setShowNotification(true);
    setType(t);
  };

  const handleSure = () => {
    setShowConfirmation(false);
    fetch(`https://pre-potts-middleware.comfort-works.com/delete_queue/?session=${session}&queue_id=${selectedQid}`)
      .then(response => response.json())
      .then(data => {
        if (data.status) {
          const sortedData = data.queue.sort((a, b) => {
            if (a.done === 1 && b.done !== 1) return 1;
            if (a.done !== 1 && b.done === 1) return -1;
            if (a.inProgress < b.inProgress) return -1;
            if (a.inProgress > b.inProgress) return 1;
            return 0;
          });
          setQueueData(sortedData);
          setNotificationFields('Termination request submitted successfully', 'success');
        } else {
          setNotificationFields('Some problem with request: ' + data?.message, 'danger');
        }
      })
      .catch((err) => {
        console.error(err?.message);
        setNotificationFields(err?.message, 'danger');
      });
  };

  const confirming = (qid) => {
    setSelectedQid(qid);
    setShowConfirmation(true);
    setConfirmationData({
      heading: "Termination",
      message: `Do you want to request the termination of Queue ID: ${qid}`,
    });
  };

  if (!session) {
    return <h4 style={{ margin: '40px auto', textAlign: 'center' }}>No session found</h4>;
  }

  if (isLoading) {
    return <img src="./loading.gif" alt="Loading..." style={{ display: 'block', margin: '40px auto' }} />;
  }

  return (
    <>
      <Container style={{ margin: '40px auto' }}>
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Queue Details</h2>
        {queueData.length > 0 ? queueData.map((queue, index) => (
          <Card key={index} style={{ marginBottom: '10px', backgroundColor: queue.done === 1 ? '#e0f5e5' : '' }}>
            <Card.Body>
            <Row>
              <Col xs={12} sm={6} md={3}><strong>Queue ID: {queue.queueId}</strong></Col>
              <Col xs={12} sm={6} md={3}>Exact Match: {queue.exactMatch ? 'Yes' : 'No'}</Col>
              <Col xs={12} sm={6} md={3}>Include Main: {queue.includeMain ? 'Yes' : 'No'}</Col>
              <Col xs={12} sm={4} md={2}>Done: {queue.done ? 'Yes' : 'No'}</Col>
              { queue.done ? '' : (
              <Col xs={12} sm={2} md={1} style={{textAlign: 'right'}}>
                <img height='20px' onClick={() => confirming(queue.queueId)} className="trash-icon" alt="delete icon" src="./trash.png" />
              </Col>
              )}
            </Row>
            <Row>
              <Col xs={12} sm={6} md={3}>Fix Variant: {queue.fixVariant ? 'Yes' : 'No'}</Col>
              <Col xs={12} sm={6} md={3}>Update Prices: {queue.updatePrices ? 'Yes' : 'No'}</Col>
              <Col xs={12} sm={6} md={3}>Archived: {queue.archived ? 'Yes' : 'No'}</Col>
              <Col xs={12} sm={6} md={3}>Select Fabrics: {queue.selectFabrics ? 'Yes' : 'No'}</Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={3}>Do Not Add Missing Fabrics: {queue.doNotAddMissingFabrics ? 'Yes' : 'No'}</Col>
              <Col xs={12} sm={6} md={3}>Disable Products: {queue.disableProducts ? 'Yes' : 'No'}</Col>
              <Col xs={12} sm={6} md={3}>Only English: {queue.onlyEnglish !== null ? (queue.onlyEnglish ? 'Yes' : 'No') : 'N/A'}</Col>
              <Col xs={12} sm={6} md={3}>In Progress: {queue.inProgress ? 'Yes' : 'No'}</Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={3}>Verify Variant: {queue.verifyVariant ? 'Yes' : 'No'}</Col>
              <Col xs={12} sm={6} md={3}>Term: {queue.term  ? 'Yes' : 'No'}</Col>
              <Col xs={12} sm={6} md={3}>Disable Fabrics: {queue?.disableFabrics ? 'Yes' : 'No'}</Col>
              <Col xs={12} sm={6} md={3}>Translate: {queue?.translate !== null ? (queue.onlyEnglish ? 'Yes' : 'No') : 'N/A'}</Col>
              
            </Row>
            <Row>
              <Col xs={12} sm={6} md={3}>Sync: {queue?.sync ? 'Yes' : 'No'}</Col>
              <Col xs={12} sm={6} md={3}>Queue size: {queue.queueSize ? queue.queueSize : 'N/A'}</Col>
              <Col xs={12} sm={6} md={3}>Current: {queue.queueCurrent ? queue.queueCurrent : 'N/A'} ({queue.products[queue.queueCurrent - 1]})</Col>
              <Col xs={12} sm={6} md={3} style={{textAlign: 'right'}}>
                <a href={`/reports?queue=${queue.queueId}`}  size="sm" variant="light">Log</a>
              </Col>
            </Row>
            </Card.Body>
          </Card>
        )) : <h4 style={{ textAlign: 'center' }}>No queue data available</h4>}
      </Container>
      <Notification
        showNotification={showNotification}
        setShowNotification={setShowNotification}
        type={type} message={message}
      />
      <Confirmation
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        heading={confirmationData.heading}
        message={confirmationData.message}
        handleSure={handleSure}
      />
    </>
  );
};

export default ProgressChecker;
